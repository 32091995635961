<template>
  <div>
    <BaseModal
      :activeModal="show"
      @closeModal="close"
      :cancel="false"
      :ok="false"
      :close="true"
      size="xl"
    >
      <template #header>
        Karta pracownika
        <font-awesome-icon v-if="Number(employee.disabled) === 1" icon="user-tie" size="sm" class="mt-1 mr-1 text-gray-400" title="wyłączony"/>
        <font-awesome-icon v-else icon="user-tie" size="sm" class="mt-1 mr-1 text-green-400" title="aktywny"/>
      </template>
      <template #default>
        <div v-if="isBusy" class="w-full p-4 h-full text-center">
          <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
        </div>
        <div v-else class="flex flex-wrap">
          <div class="flex flex-col w-full sm:w-1/2 mb-2 sm:pr-3"> <!-- left block wrapper-->

            <div class="relative w-full mb-3"> <!-- employee block-->
              <MicroMenu>
                <template #default>
                  <ul>
                    <li><a href="" @click.prevent="showEditEmployee = true"><font-awesome-icon icon="edit" class="text-blue-500 mr-1" /> edytuj</a></li>
                    <li v-if="Number(employee.disabled) === 0"><a href="" @click.prevent="disableEmployee(1)"><font-awesome-icon icon="user-tie" class="text-red-500 mr-1" /> wyłącz</a></li>
                    <li v-else><a href="" @click.prevent="disableEmployee(0)"><font-awesome-icon icon="user-tie" class="text-green-500 mr-1" /> włącz</a></li>
                  </ul>
                </template>
              </MicroMenu>
              <h1 class="font-semibold leading-7 text-gray-600">Dane kadrowe</h1>
              <hr>
              <table class="mt-0">
                <tr>
                  <td class="font-semibold text-gray-500">role w aplikacji:</td>
                  <td><span v-for="role in employee.roles" :key="role.id" class="px-1 bg-teal-200 rounded mr-1">{{ role.label }}</span></td>
                </tr>
                <tr>
                  <td class="font-semibold text-gray-500">zarządza prac.: </td>
                  <td>{{ Number(employee.is_manager) === 1 ? 'Tak' : 'Nie' }}</td>
                </tr>
                <tr v-if="employee.manager">
                  <td class="font-semibold text-gray-500">manager:</td>
                  <td><router-link :to="{ name: 'employee', params: { id: employee.manager_id }}">{{ `${employee.manager.person.first_name} ${employee.manager.person.surname}` }}</router-link></td>
                </tr>
                <tr>
                  <td class="font-semibold text-gray-500">forma zatrudn.:</td>
                  <td>{{ employee.employmentForm.name }}</td>
                </tr>
              </table>
            </div> <!-- end of employee block -->

            <div class="relative w-full mb-3"> <!-- person block -->
              <div @click="showEditPerson = true" class="absolute top-0 right-0 mt-1 px-2 mr-1 cursor-pointer text-blue-500 hover:text-blue-600" title="Edytuj">
                <font-awesome-icon icon="edit" />
              </div>
              <h1 class="font-semibold leading-7 text-gray-600">Dane personalne</h1>
              <hr>
              <div class="mt-0">
                <p class="text-xl font-semibold">{{ employee.person.first_name }} {{ employee.person.middle_name }} {{ employee.person.surname }}</p>
                <p v-show="employee.person.pesel_passport || employee.person.birth_date">
                  <span v-if="employee.person.pesel_passport" class="mr-3"><em class="text-gray-500">pesel:</em> {{employee.person.pesel_passport}}</span>
                  <span v-if="employee.person.birth_date"><em class="text-gray-500">data ur.:</em> {{employee.person.birth_date}}</span>
                </p>
                <div class="flex flex-wrap justify-start">
                  <span class="mr-3"><font-awesome-icon icon="phone" />&nbsp;
                    <a v-if="employee.person.phone" :href="`tel:${employee.person.phone}`">
                      <span v-if="Number(employee.person.regional_phone) === 1">{{ employee.person.phone | phoneNo}}</span>
                      <span v-else>{{ employee.person.phone }} <span class="text-xs text-gray-500"><em>zagraniczny</em></span></span>
                    </a>
                    <span v-else class="text-gray-500 text-xs">nie podano</span>
                  </span>
                  <span><font-awesome-icon icon="at" />&nbsp;
                    <a v-if="employee.person.email" :href="`mailto:${employee.person.email}`">{{ employee.person.email }}</a>
                    <span v-else class="text-gray-500 text-xs">nie podano</span>
                  </span>
                </div>
                <p>
                  <span v-if="employee.person.address1">
                    {{ employee.person.address1.street }} {{ employee.person.address1.house_apt_no }};
                    {{ employee.person.address1.postal_code }} {{ employee.person.address1.city }}<br>
                    <span class="text-sm text-gray-500">region:</span> {{ employee.person.address1.region ? employee.person.address1.region.name : '' }}
                  </span>
                </p>
                <p>
                  <span class="font-semibold text-gray-600">konto bank: </span>
                  <span v-if="employee.person.bank_acct_no">
                    {{ employee.person.bank }} {{ employee.person.bank_acct_no | bankAcc }}
                  </span>
                  <span v-else class="text-sm text-gray-500">nie podano</span>
                </p>
              </div>
            </div> <!-- end of person block -->

            <div class="relative w-full mb-3"> <!-- company block -->
              <MicroMenu v-if="employee.company">
                <template #default>
                  <ul>
                    <li><a href="" @click.prevent="showEditCompany = true"><font-awesome-icon icon="edit" class="text-blue-500 mr-1" /> edytuj</a></li>
                    <li><a href="" @click.prevent="deleteCompany"><font-awesome-icon icon="times" class="text-red-500 mr-1" /> usuń</a></li>
                  </ul>
                </template>
              </MicroMenu>
              <div v-else @click="showAddCompany = true" class="absolute top-0 right-0 mt-1 px-2 mr-1 cursor-pointer text-blue-500 hover:text-blue-600" title="Dodaj dane do faktury">
                <font-awesome-icon icon="plus" />
              </div>
              <h1 class="font-semibold leading-7 text-gray-600">Dane do faktury</h1>
              <hr>
              <div v-if="employee.company" class="mt-0">
                <p class="font-semibold">{{ employee.company.name }}</p>
                <p><span class="text-gray-500">NIP:</span> {{employee.company.nip}}</p>
                <p>
                  <span v-if="employee.company.address1">
                    {{ employee.company.address1.street }} {{ employee.company.address1.house_apt_no }};
                    {{ employee.company.address1.postal_code }} {{ employee.company.address1.city }}<br>
                  </span>
                </p>
                <p>
                  <span class="font-semibold text-gray-600">konto bank: </span>
                  <span v-if="employee.company.bank_acct_no">
                    {{ employee.company.bank }} {{ employee.company.bank_acct_no | bankAcc }}
                  </span>
                  <span v-else class="text-sm text-gray-500">nie podano</span>
                </p>
              </div>
              <div v-else class="mt-3">
                dodaj firmę
              </div>
            </div> <!-- end of company block -->
          </div> <!-- end of left block wrapper -->

          <div class="flex flex-col w-full sm:w-1/2 sm:pl-3">  <!-- right block wrapper -->

            <div class="relative w-full mb-3"> <!-- user block-->
              <MicroMenu v-if="employee.user">
                <template #default>
                  <ul>
                    <li><a href="" @click.prevent="showEditUser = true"><font-awesome-icon icon="edit" class="text-blue-500 mr-1" /> edytuj</a></li>
                    <template v-if="Number(employee.user.blocked) === 0 && Number(employee.user.disabled) === 0">
                      <li><a href="" @click.prevent="blockUser(1)"><font-awesome-icon icon="user-lock" class="text-yellow-500 mr-1" /> zablokuj</a></li>
                      <li><a href="" @click.prevent="disableUser(1)"><font-awesome-icon icon="user-times" class="text-red-500 mr-1" /> wyłącz</a></li>
                    </template>
                    <template v-else-if="Number(employee.user.blocked) === 1 && Number(employee.user.disabled) === 0">
                      <li><a href="" @click.prevent="blockUser(0)"><font-awesome-icon icon="user-check" class="text-green-500 mr-1" /> odblokuj</a></li>
                      <li><a href="" @click.prevent="disableUser(1)"><font-awesome-icon icon="user-times" class="text-red-500 mr-1" /> wyłącz</a></li>
                    </template>
                    <li v-else-if="Number(employee.user.disabled) === 1"><a href="" @click.prevent="disableUser(0)"><font-awesome-icon icon="user-check" class="text-green-500 mr-1" /> włącz</a></li>
                    <li><a href="" @click.prevent="showPass = true"><font-awesome-icon icon="key" class="text-orange-700 mr-1" /> zm. hasło</a></li>
                  </ul>
                </template>
              </MicroMenu>
              <div v-else @click="showAddUser = true" class="absolute top-0 right-0 mt-1 px-2 mr-1 cursor-pointer text-blue-500 hover:text-blue-600" title="Dodaj dane do logowania">
                <font-awesome-icon icon="plus" />
              </div>
              <h1 class="font-semibold leading-7 text-gray-600">Dane logowania</h1>
              <hr>
              <div v-if="employee.user">
                <table class="mt-3">
                  <tr>
                    <td class="font-semibold text-gray-500">login:</td>
                    <td>{{ employee.user.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold text-gray-500">email:</td>
                    <td><a :href="`mailto:${employee.user.email}`">{{ employee.user.email }}</a></td>
                  </tr>
                  <tr>
                    <td class="font-semibold text-gray-500">status:</td>
                    <td>
                      <span v-if="Number(employee.user.disabled) === 1" class="text-red-500">Wyłączony</span>
                      <span v-else-if="Number(employee.user.blocked) === 1" class="text-yellow-500">Zablokowany</span>
                      <span v-else class="text-green-500">Aktywny</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div> <!-- end of user block -->

            <Notes
              title="Notatki i załączniki"
              :noteableId="employee.id"
              noteableType="App\Employee"
              categoryId="1"
              :notes="employee.notes"
              :token="employee.images_token"
              @added="noteAdded"
              @changed="noteChanged"
              @deleted="noteDeleted"
              @deletedMany="noteDeletedMany"
            />


          </div> <!-- end of right block wrapper -->
        </div>
      </template>
    </BaseModal>
    <EmployeeEdit
      v-if="showEditEmployee"
      @close="showEditEmployee = false"
      @changed="employeeChanged"
      :employee="employee"
    ></EmployeeEdit>
    <UserEdit
      v-if="showEditUser"
      @close="showEditUser = false"
      @changed="userChanged"
      :user="employee.user"
    ></UserEdit>
    <UserChangePass
      v-if="showPass"
      @close="showPass = false"
      @changed="passChanged"
      :user="employee.user"
    ></UserChangePass>
    <PersonEdit
      v-if="showEditPerson"
      @close="showEditPerson = false"
      @changed="personChanged"
      :person="employee.person"
    ></PersonEdit>
    <CompanyEdit
      v-if="showEditCompany"
      @close="showEditCompany = false"
      @changed="companyChanged"
      :company="employee.company"
    ></CompanyEdit>
    <CompanyAdd
      v-if="showAddCompany"
      @close="showAddCompany = false"
      @added="companyAdded"
    ></CompanyAdd>
    <UserAdd
      v-if="showAddUser"
      @close="showAddUser = false"
      @added="userAdded"
      :person="employee.person"
    ></UserAdd>
  </div>
</template>

<script>
import EmployeeService from '../services/EmployeeService';
import UserService from '../services/UserService';
import CompanyService from '../services/CompanyService';
import MicroMenu from '../components/MicroMenu.vue';
import EmployeeEdit from '../components/EmployeeEdit.vue';
import UserEdit from '../components/UserEdit.vue';
import UserChangePass from '../components/UserChangePass.vue';
import PersonEdit from '../components/PersonEdit.vue';
import CompanyEdit from '../components/CompanyEdit.vue';
import CompanyAdd from '../components/CompanyAdd.vue';
import UserAdd from '../components/UserAdd.vue';
import Notes from '../components/Notes.vue';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';

export default {
  name: 'EmployeeItem',
  components: {
    MicroMenu,
    EmployeeEdit,
    UserEdit,
    UserChangePass,
    PersonEdit,
    CompanyEdit,
    CompanyAdd,
    UserAdd,
    Notes,
  },
  mixins: [errorHandler, confirm],
  data() {
    return {
      show: false,
      showEditEmployee: false,
      showEditUser: false,
      showPass: false,
      showEditPerson: false,
      showEditCompany: false,
      showAddCompany: false,
      showAddUser: false,
      isBusy: true,
      employee: {
        notes: [],
        images_token: '',
        person: {
          address1: {},
        },
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.show = true;
    this.getEmployee();
  },
  methods: {
    getEmployee() {
      EmployeeService.getEmployee(this.id)
        .then(response => {
          this.employee = response.data.result;
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error); // method from mixin
        });
    },
    employeeChanged(employee) {
      this.employee = employee;
      this.$store.dispatch('updates/employeeListNeedsUpdate');
    },
    userChanged(user) {
      this.getEmployee();
      console.log(user);
      // this.setUserData(user);
      // this.$store.dispatch('updates/userListNeedsUpdate');
    },
    userAdded(user) {
      this.employee.user = user;
    },
    passChanged() {
      // Tu komunikat o sukcesie
    },
    personChanged() {
      this.getEmployee();
      this.$store.dispatch('updates/employeeListNeedsUpdate');
    },
    companyChanged() {
      this.getEmployee();
      // this.$store.dispatch('updates/employeeListNeedsUpdate');
    },
    companyAdded(newCompany) {
      this.updateEmployee({ company_id: newCompany.id });
    },
    deleteCompany() {
      CompanyService.deleteCompany(this.employee.company.id)
        .then(() => this.getEmployee())
        .catch(error => this.resolveError(error));
    },
    disableEmployee(val) {
      this.confirm(() => this.updateEmployee({ disabled: val }), `Czy na pewno ${val === 0 ? 'włączyć ponownie' : 'wyłączyć'} pracownika?`);
    },
    blockUser(val) {
      this.confirm(() => this.updateUser({ blocked: val }), `Czy na pewno ${val === 0 ? 'odblokować' : 'zablokować'} użytkownika?`);
    },
    disableUser(val) {
      this.confirm(() => this.updateUser({ disabled: val }), `Czy na pewno ${val === 0 ? 'włączyć ponownie' : 'wyłączyć'} konto użytkownika?`);
    },
    close() {
      const { query } = this.$route;
      this.$router.push({ name: 'employees', query });
    },
    updateEmployee(query) {
      this.isBusy = true;
      EmployeeService.updateEmployee(this.id, query)
        .then(response => {
          this.employee = response.data.result;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
          this.isBusy = false;
          this.$store.dispatch('updates/employeeListNeedsUpdate');
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    updateUser(query) {
      this.isBusy = true;
      UserService.updateUser(this.employee.user.id, query)
        .then(() => {
          Object.keys(query).forEach(key => {
            this.employee.user[key] = query[key];
          });
          // this.setUserData(response.data.result);
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    noteAdded(note) {
      this.employee.notes.unshift(note);
    },
    noteChanged(note) {
      const oldNote = this.employee.notes.find(obj => Number(obj.id) === Number(note.id));
      Object.assign(oldNote, note);
    },
    noteDeleted(id) {
      this.employee.notes = this.employee.notes.filter(obj => Number(obj.id) !== Number(id));
    },
    noteDeletedMany(ids) {
      const idsDeleted = ids.map(el => Number(el));
      this.employee.notes = this.employee.notes.filter(obj => !idsDeleted.includes(obj.id));
    },
  },
  watch: {
    $route(to, from) {
      if (JSON.stringify(to.params) !== JSON.stringify(from.params)) {
        this.getEmployee();
      }
    },
  },
};
</script>

<style scoped>
  td {
    @apply pr-3;
  }
</style>
