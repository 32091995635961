<template>
  <div class="absolute top-0 right-0" ref="microMenu">
    <div class="absolute top-0 right-0 mt-1 px-2 mr-1 cursor-pointer text-blue-500 hover:text-blue-600" @click="opened = !opened" title="Menu">
      <font-awesome-icon icon="bars" />
    </div>
    <div v-show="opened" class="mt-6 mr-3 bg-gray-100 border border-gray-500 rounded shadow px-4 py-1">
      <ul ref="menuContent">
        <slot>
          <li><a href="" @click.prevent="opened = false"><font-awesome-icon icon="times" class="text-gray-500" /> Zamknij</a></li>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MicroMenu',
  data() {
    return {
      opened: false,
    };
  },
  created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    documentClick(e) {
      const el = this.$refs.microMenu;
      const content = this.$refs.menuContent;
      const { target } = e;
      if ((el !== target && !el.contains(target)) || content === target || content.contains(target)) {
        this.opened = false;
      }
    },
  },
};
</script>
