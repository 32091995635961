<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Dodawanie użytkownika
    </template>
    <template #default>
      <div class="mb-4">
        <label>login</label>
        <input v-focus @keyup.enter="save" @keyup.esc="show = false" :class="{ 'border-red-300': errors.name !== undefined }" v-model="form.name" type="text">
        <p v-if="errors.name !== undefined" class="text-red-500 text-sm">{{ errors.name }}</p>
      </div>
      <div class="mb-4">
        <label>email</label>
        <input @keyup.enter="save" @keyup.esc="show = false" :class="{ 'border-red-300': errors.email !== undefined }" v-model="form.email" type="email">
        <p v-if="errors.email !== undefined" class="text-red-500 text-sm">{{ errors.email }}</p>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import UserService from '../services/UserService';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'UserEdit',
  mixins: [errorHandler],
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: '',
        email: this.person.email ? this.person.email : '',
        person_id: this.person.id,
      },
      errors: {},
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    save() {
      UserService.addUser(this.form)
        .then(response => {
          const newUser = response.data.result;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Użytkownik został dodany...',
          });
          this.$emit('added', newUser);
          this.show = false;
        })
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
  },
};
</script>

<style scoped>
  label {
    @apply block;
    @apply font-semibold;
  }
  input, select {
    @apply w-full;
    @apply px-3;
    @apply py-1;
    @apply border;
    @apply rounded-sm;
  }
</style>
