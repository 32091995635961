<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Edycja danych kadrowych
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>Role w aplikacji <span class="text-gray-500 font-thin text-xs">wybierz jedną lub kilka</span></label>
          <select @change="addRole" v-model="selectedRole">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="role in notSelectedRoles" :key="role.id" :value="role.id">{{ role.label }}</option>
          </select>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>Aktualnie wybrane:</label>
          <div class="bg-white w-full border border-gray-400 rounded-sm px-3 py-1">
            <span v-if="selectedRoles.length === 0" class="text-gray-500 leading-1">nie wybrano</span>
            <span v-for="role in selectedRoles" :key="role.id" class="text-sm whitespace-no-wrap inline-block bg-orange-400 px-2 mr-1 rounded">
              {{ role.label }}
              <button @click="removeRole(role.id)" class="ml-1" title="usuń"><font-awesome-icon icon="times"/></button>
            </span>
          </div>
        </div>
        <div class="w-full px-1 mb-4">
          <label>
            <input class="w-auto" v-model="form.is_manager" true-value="1" false-value="0" type="checkbox">
            czy zarządza innymi pracownikami? <span class="text-gray-500 font-thin text-xs tracking-wide">zaznacz, jeśli TAK</span>
          </label>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>Manager</label>
          <select v-model="form.manager_id">
            <option value=""></option>
            <option v-for="manager in managers" :key="manager.id" :value="manager.id">{{ `${manager.first_name} ${manager.surname}` }}</option>
          </select>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>Forma zatrudnienia</label>
          <select v-model="form.employment_form_id">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="empForm in employmentForms" :key="empForm.id" :value="empForm.id">{{ empForm.name }}</option>
          </select>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import EmployeeService from '../services/EmployeeService';
import { errorHandler } from '../mixins/errorHandler';

export default {
  name: 'EmployeeEdit',
  mixins: [errorHandler],
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        roles: [],
        is_manager: '',
        manager_id: '',
        employment_form_id: '',
      },
      employmentForms: [],
      selectedRole: '',
      errors: {},
      show: false,
    };
  },
  computed: {
    employeeRoles() {
      return this.$store.getters['roles/employeeRoles'];
    },
    managers() {
      return this.$store.getters['managers/managers'];
    },
    selectedRoles() {
      return this.employeeRoles.filter(obj => this.form.roles.includes(obj.id));
    },
    notSelectedRoles() {
      return this.employeeRoles.filter(obj => !this.form.roles.includes(obj.id));
    },
  },
  mounted() {
    if (this.employeeRoles.length === 0) {
      this.$store.dispatch('roles/getEmployeeRoles');
    }
    if (this.managers.length === 0) {
      this.$store.dispatch('managers/getManagers');
    }
    this.getEmploymentForms();
    this.setForm();
    this.show = true;
  },
  methods: {
    save() {
      let editedEmployee = {};
      EmployeeService.updateEmployee(this.employee.id, this.form)
        .then(response => {
          editedEmployee = response.data.result;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
          this.$emit('changed', editedEmployee);
          this.show = false;
        })
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
    getEmploymentForms() {
      axios.get(`${process.env.VUE_APP_API_URL}/employment-forms`)
        .then(response => {
          this.employmentForms = response.data;
        })
        // eslint-disable-next-line no-console
        .catch(error => console.log(error));
    },
    addRole() {
      if (this.selectedRole !== '') {
        this.form.roles.push(this.selectedRole);
        this.selectedRole = '';
      }
    },
    removeRole(id) {
      this.form.roles = this.form.roles.filter(el => el !== id);
    },
    setForm() {
      this.form.roles = this.employee.roles.map(obj => obj.id);
      this.form.is_manager = this.employee.is_manager;
      this.form.manager_id = this.employee.manager_id;
      this.form.employment_form_id = this.employee.employment_form_id;
    },
  },
};
</script>
